/* Import here all global assets */
import 'core-js/stable'
import 'regenerator-runtime/runtime'
import '@helpers/async-utilities'
import 'objectFitPolyfill'

// Static images
import '@images/barilla.svg'
import '@images/barilla-placeholder.svg'
import '@images/barilla-placeholder220.svg'
import '@images/blue-map-marker.svg'
import '@images/barilla-tw-pic.png'
import '@images/home/home-heading-image.png'
import '@images/home/home-hero.png'
import '@images/home/home-green-fields.png'
import '@images/home/home-brand-barilla.png'
import '@images/home/home-brand-mulino.png'
import '@images/home/home-brands-barilla.jpg'
import '@images/home/home-brands-mulino-bianco.jpg'
import '@images/home/home-brands-pan-di-stelle.jpg'
import '@images/home/home-brands-wasa.jpg'
import '@images/home/home-historical-archive.jpg'
import '@images/home/home-in-the-news-slide.jpg'
import '@images/home/home-mission-m-01.jpg'
import '@images/home/home-mission-m-02.jpg'
import '@images/home/home-mission-d-01.jpg'
import '@images/home/home-mission-d-02.jpg'

import '@images/brands/brands-barilla.png'
import '@images/brands/brands-barilla-header.jpg'
import '@images/brands/brands-mulino-bianco-logo.png'
import '@images/brands/brands-wasa-header.jpg'
import '@images/brands/brands-wasa-logo.png'
import '@images/brands/brands-casa-barilla-logo.png'
import '@images/brands/brands-barilla-logo.png'

import '@images/about/about-header.jpg'
import '@images/about/about-hub.png'
import '@images/about/about-family.jpg'
import '@images/about/about-family-company.png'
import '@images/about/about-history-header.jpg'
import '@images/about/about-history-video.jpg'
import '@images/about/about-figures-header.jpg'
import '@images/about/about-get-in-touch.png'
import '@images/about/about-values-header.jpg'
import '@images/about/about-values-video.jpg'
import '@images/about/about-bcfn-header.png'
import '@images/about/about-bcfn-video.jpg'
import '@images/about/about-guido.png'
import '@images/about/about-luca.png'
import '@images/about/about-paolo.png'
import '@images/about/about-colzani.png'
import '@images/about/about-values-courage.jpg'
import '@images/about/about-values-curiosity.jpg'
import '@images/about/about-values-integrity.jpg'
import '@images/about/about-values-passion.jpg'
import '@images/about/about-values-trust.jpg'
import '@images/about/about-bcfn-foundation.jpg'
import '@images/about/about-family-company.jpg'
import '@images/about/about-position.jpg'
import '@images/about/about-position-header.jpg'
import '@images/about/about-position-box1.jpg'
import '@images/about/about-position-box2.jpg'
import '@images/about/about-position-sustainable-packaging.svg'
import '@images/about/history-hero.jpg'
import '@images/about/about-history-wheat.jpg'
import '@images/about/about-values-hero.jpg'
import '@images/about/about-values-01.jpg'
import '@images/about/about-values-02.jpg'
import '@images/about/about-values-03.jpg'
import '@images/about/about-values-04.jpg'

import '@images/news/stories-archive-video.jpg'
import '@images/news/news-off-grid-pic.png'
import '@images/news/news-story-detail-hero.png'
import '@images/news/news-pic-preview.png'
import '@images/news/news-pic-detail.png'
import '@images/news/news-gallery-01.png'
import '@images/news/news-gallery-02.png'
import '@images/news/news-gallery-03.png'
import '@images/news/news-historical-01.jpg'
import '@images/news/news-historical-02.jpg'
import '@images/news/news-sust-report.jpg'
import '@images/news/news-eco-report.jpg'

import '@images/brands/carousel-harrys.png'
import '@images/brands/carousel-barilla.png'
import '@images/brands/carousel-pandistelle.png'
import '@images/brands/carousel-mulinobianco.png'
import '@images/brands/carousel-wasa.png'
import '@images/brands/brands-wasa-recipes.png'
import '@images/brands/brands-wasa-rye.jpg'
import '@images/brands/brands-harrys.png'
import '@images/brands/brands-harrys-bg-1.png'
import '@images/brands/brands-harrys-bg-2.png'
import '@images/brands/brands-harrys-bg-3.png'
import '@images/brands/brands-harrys-bg-4.png'
import '@images/brands/brands-harrys-related.png'
import '@images/brands/brands-wasa-forest.jpg'
import '@images/brands/brands-wasa-co2.jpg'
import '@images/brands/brands-wasa-sour.jpg'
import '@images/brands/brands-wasa-madre.jpg'
import '@images/brands/brands-mulino-page-header.jpg'
import '@images/brands/brands-mulino-bread.jpg'
import '@images/brands/brands-mulino-charta.jpg'
import '@images/brands/brands-mulino-food.jpg'
import '@images/brands/brands-mulino-commitment.jpg'
import '@images/brands/brands-mulino-vorrei.jpg'
import '@images/brands/brands-barilla-flour.jpg'
import '@images/brands/brands-barilla-production.jpg'
import '@images/brands/brands-barilla-bio-sauce.jpg'
import '@images/brands/brands-barilla-hero.jpg'
import '@images/brands/brands-barilla-reverse.jpg'
import '@images/brands/brands-barilla-rotini.png'
import '@images/brands/brands-barilla-quality-manifesto.png'
import '@images/brands/brands-pan-hero.jpg'
import '@images/brands/brands-pan-school.jpg'
import '@images/brands/brands-pan-river.jpg'
import '@images/brands/brands-pan-stars-top.png'
import '@images/brands/brands-pan-stars-body.png'
import '@images/brands/brands-pan-logo.png'
import '@images/brands/brands-pan-cocoa.jpg'
import '@images/brands/brands-pan-girl.png'

import '@images/careers/careers-index-hero.jpg'
import '@images/careers/careers-index-part.jpg'
import '@images/careers/careers-work-at-hero.jpg'
import '@images/careers/careers-words-01.jpg'
import '@images/careers/careers-asymmetric-1-1.jpg'
import '@images/careers/careers-asymmetric-1-2.jpg'
import '@images/careers/careers-asymmetric-2-1.jpg'
import '@images/careers/careers-asymmetric-2-2.jpg'
import '@images/careers/careers-biscuits.jpg'
import '@images/careers/careers-recruiting-process-1.png'
import '@images/careers/careers-recruiting-process-2.png'
import '@images/careers/careers-opportunities-grid.jpg'
import '@images/careers/careers-job-detail-header.jpg'

import '@images/mission/mission-good-for-the-planet.jpg'
import '@images/mission/mission-good-for-you.jpg'
import '@images/mission/mission-targets-bg.jpg'
import '@images/mission/mission-diversity-banner.jpg'
import '@images/mission/mission-gfp-large.jpg'
import '@images/mission/mission-gfp-farming.jpg'
import '@images/mission/mission-gfp-2030-banner.jpg'
import '@images/mission/mission-gfp-animals.jpg'
import '@images/mission/mission-gfp-gfy-banner.jpg'
import '@images/mission/mission-gfp-cocoa.png'
import '@images/mission/mission-gfp-wheat.png'
import '@images/mission/mission-gfp-tomatoes.png'
import '@images/mission/mission-gfj-banner.jpg'
import '@images/mission/mission-gfj-header.jpg'
import '@images/mission/mission-gfj-palm.jpg'
import '@images/mission/mission-forward-01.jpg'
import '@images/mission/mission-forward-02.jpg'

import '@images/mission/mission-diversity-banner1.jpg'
import '@images/mission/mission-diversity-banner2.jpg'
import '@images/mission/mission-diversity-ethics.jpg'
import '@images/mission/mission-diversity-rights.jpg'
import '@images/mission/david-mixner.jpg'
import '@images/mission/kristen-anderson.jpg'
import '@images/mission/lisa-kepinski.jpg'
import '@images/mission/mission-diversity-board-face-1.jpg'
import '@images/mission/mission-diversity-board-face-2.jpg'
import '@images/mission/mission-diversity-board-face-3.jpg'
import '@images/mission/mission-diversity-board-face-4.jpg'
import '@images/mission/mission-diversity-board-face-5.jpg'
import '@images/mission/mission-diversity-board-face-6.jpg'
import '@images/mission/mission-diversity-board-face-7.jpg'
import '@images/mission/mission-diversity-board-face-8.jpg'
import '@images/mission/mission-diversity-refugees.jpg'
import '@images/mission/mission-diversity-supplier.jpg'
import '@images/mission/mission-diversity-racial.jpg'
import '@images/mission/mission-diversity-office.jpg'
import '@images/mission/mission-diversity-lgbt-openly.jpg'
import '@images/mission/mission-diversity-girls.jpg'
import '@images/mission/mission-planet-hero.jpg'

// Stylesheets
import '$scss/common.scss'
